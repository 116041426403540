import AnimationRevealPage from 'helpers/AnimationRevealPage';
import { useHistory } from 'react-router-dom';
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
// import ThreeColSimple from "components/cards/ThreeColSlider";
import { image1, joi3 } from 'Common/Images';
import clientLogo from './clientLogo.png'



const AllProject = () => {

    const history = useHistory();
    const cards = [
        {
            imageSrc: joi3,
            title: "Jewels of India - 2",
            description: "We are proud to be digitally managing the project Jewel of India, Jaipur by Suncity projects. Jewel of India is a residential development of 1.5 million sq ft and the most successful project of the state. ",
            locationText: "Jaipur, Rajasthan",
            pricingText: "USD 39/Day",
            rating: "4.8",
            onClick: () => {
                history.push('/project/joi2')
            }
        },
        {
            imageSrc: image1,
            title: "Horizon Tower",
            description: "Buildrun is also digitally managing Horizon tower, a .5 million sq ft of Commercial developments. Buildrun auto schedules the project on any finish of the various tasks, auto stores the pictures and also creates and share auto daily progress reports.",
            locationText: "Jaipur, Rajasthan",
            pricingText: "USD 39/Day",
            rating: "4.8",
            onClick: () => {
                history.push('/project/horizon')
            }
        }
    ]

    return (
        <AnimationRevealPage>
            <Header />
            {/* <ThreeColSimple
                title="All Project"
                cards={cards}
            /> */}
            <div style={{ fontFamily: "sans-serif", padding: "40px 0px", marginTop: "55px", borderRadius: "10px", marginBottom: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <h1 style={{ fontSize: "23px", fontWeight: "700" }}>BuildRun Now Empowers Over 10Mn+ Sq.ft</h1>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", margin: "auto", width: "80vw", marginBottom: "40px", marginTop: '40px', alignItems: "center" }}>
                    <img
                        src={clientLogo}
                        alt="error"
                        className="logo"
                    />
                </div>
            </div>
            <Footer />
        </AnimationRevealPage>
    )
}

export default AllProject;

