import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Pricing from "components/pricing/ThreePlans.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import easyimage from '../Common/Images/easy.png';
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={easyimage}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Report.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={easyimage}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          // {
          //   Icon: BriefcaseIcon,
          //   title: "Artificial intelligence",
          //   description: "We assure you that our artificial intelligence system ",
          //   iconContainerCss: tw`bg-red-300 text-red-800`
          // }
        ]}
      />
     
      {/* s */}
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Why does project get late? How does Buildrun help?",
            answer:
              "Several work hours are required by different site staffs just to update the daily work progress and make changes in the planning including all deviations from the original schedule. Spending such workhours everyday can be a costly affair or not taken on priority. Buildrun provides economical Artificial Intelligence tool that auto updates the planning based on today’s work done and makes the completion date of the project readily visible at any point of time. "
          },
          {
            question: "What are the advantages of using Buildrun over hiring a dedicated planner/s for a project? ",
            answer:
              "Buildrun believes in lean hierarchy at the site and also saving manpower hours from wastage while updating with the available mundane project tracking tools. Every Staff at the site saves 2 hours every day with the usage of the Buildrun, which can be utilized on processes enhancing quality or saving costs. A costlier dedicated effective planner is hard to find and still is prone to Chinese whisper / telephone game errors due to improper communications spread on emails, messenger apps, phone or in person"
          },
          {
            question: "What are the advantages of BUILDRUN over previous tools like MSP, Excel, sheets or any other tools?",
            answer:
              "Buildrun encourages the team to finish the work in time by providing an individual based score cards. In addition, it is an online platform syncing all stakeholders like managements, vendors, employees on the same page. In the other tools, data feeding, analyzing, rescheduling the worklist for a day, were done by a single person. The absence of this person for a day or during the work hour can make the worklist/ data incomplete. As a result, the updates on the site will be incorrect which leads to a wrong reporting towards the end of the day. This affects the whole process for the coming days too"
          },
          {
            question: "What is the day journey of a site staff with Buildrun App ?",
            answer:
              "Based on the previous day’s work report given on the app, all the site-team members receive the daily schedule of work to be monitored on the app. Team members monitor the work and just have to take a picture of the work on the app. Based on the input on the app, the Buildrun provides the schedule of the next processes and also a new completion date of the project if any of the task is delayed which can affect the project completion."
          },
          {
            question: "What is critical path in a project, what does Builrun do with it ?",
            answer:
              "A critical path in a project is the longest process path with activities that have slack time as zero. In simple terms, any task on the critical path if delayed even by a day, the project is more likely to finish a day later unless later activities on the critical path is shortened. Based on everyday activities status, this critical path can change in a project. Without the tools, it is not possible to know about the critical path everyday. Buildrun not only auto-calculate the new schedule but also provides the label to every task that is on the critical path. It helps all the stake holders concentrate on such critical tasks every minute. "
          },
         
        ]}
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
