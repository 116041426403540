import axios from 'axios';
import React from 'react';
import Moment from 'moment';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './index.css';

class Approve extends React.Component {

    constructor(p) {
        super(p);
        this.state ={
            details:undefined,
            loading:true,
            success:false,
            decline:false
        }
    }



    componentDidMount() {
        const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
        axios({
            method:'post',
            url:'https://api.buildrun.app/main/website/actions/fetchApprovalDetails',
            data:{
                token
            }
        }).then(r=>{
            this.setState({
                ...this.state,
                details:r.data.taskApprovals,
                loading:false
            })

        })
        
    }

    
     mapItem = (value,key) => {
        return(
            <div style ={{
                display:"flex",
                borderBottom:'1px solid #999',
                flexDirection:'column',
                paddingBottom:10,
                marginTop:20
            }}>
                <p style ={{fontSize:13,color:"#777"}}>{key}</p>
                <p style ={{fontSize:16}}>{value}</p>
            </div>
        )
    }

render() {

    if(this.state.loading) {
        return <p>Loading...</p>
    }

    if(this.state.success) {
        return <p style ={{
            fontWeight:'800',
            fontSize:32,
            padding:20
        }}>Task Approved successfully</p>
    }
    if(this.state.decline) {
        return <p style ={{
            fontWeight:'800',
            fontSize:32,
            padding:20
        }}>Task Decline successfully</p>
    }
    return(
        <div style ={{
            padding:"20px"
        }}>
            <div style={{
                flex:1,
              
            }}>
                <div>{this.mapItem(this.state.details.projectDetails.name,"Project Name")}</div>
                <div>{this.mapItem(this.state.details.taskMappingDetails.taskMappingName,"Task Mapping Name")}</div>
                <div>{this.mapItem(this.state.details.taskMappingDetails.totalDaysRequired,"Total Days Required")}</div>

                <div>{this.mapItem(Moment(this.state.details.taskMappingDetails.plannedStartDate).format('DD-MM-YYYY'),"Planned Start Date")}</div>
                <div>{this.mapItem(Moment(this.state.details.taskMappingDetails.plannedCompletionDate).format('DD-MM-YYYY'),"Planned Completion Date")}</div>

                <div>{this.mapItem(Moment(this.state.details.taskMappingDetails.actualStartDate).format('DD-MM-YYYY'),"Actual Start Date")}</div>
                <div>{this.mapItem(Moment(this.state.details.taskMappingDetails.actualCompletionDate).format('DD-MM-YYYY'),"Actual Completion Date")}</div>
                <div>{this.mapItem(Moment(this.state.details.taskMappingDetails.estimatedStartDate).format('DD-MM-YYYY'),"Estimated Start Date")}</div>
                <div>{this.mapItem(Moment(this.state.details.taskMappingDetails.estimatedCompletionDate).format('DD-MM-YYYY'),"Estimated Completion Date")}</div>

                <div>{this.mapItem(this.state.details.responsiblePersonName,"Responsible person Name")}</div>
                <div>{this.mapItem(this.state.details.responsiblePersonCode,"Reponsilble person Code")}</div>

                {
                    <Carousel>
                        {this.state.details.markedImages.map((val,index)=>{
                            return(
                                <div  key={index}>
                                    <img alt={`${index}`} src={val.image} style={{height:700,objectFit:'contain'}}  />
                                    <p className="legend">{val.remarks}</p>
                                </div>
                            )
                        })}
                      
                    </Carousel>
                }

                {this.state.details.isApprover ?
                <>
                <div style ={{
                    backgroundColor:"Background",
                    padding:"12px 12px",
                    textAlign:'center',
                    color:"#fff",
                    textTransform:'uppercase',
                    fontWeight:"700",
                    borderRadius:4,
                    cursor:'pointer',
                    marginTop:20
                }} onClick={()=>{
                    const search = this.props.location.search; // could be '?foo=bar'
                    const params = new URLSearchParams(search);
                    const token = params.get('token');
                    axios({
                        method:'post',
                        url:'https://api.buildrun.app/main/website/actions/approve',
                        data:{
                            token
                        }
                    }).then(r=>{
                        this.setState({
                            ...this.state,
                            success:true
                        })
                    })
                }}>
                    Approve
                </div>

                <div style ={{
                    backgroundColor:"orange",
                    padding:"12px 12px",
                    textAlign:'center',
                    color:"#fff",
                    textTransform:'uppercase',
                    fontWeight:"700",
                    borderRadius:4,
                    cursor:'pointer',
                    marginTop:20
                }} onClick={()=>{
                    const search = this.props.location.search; // could be '?foo=bar'
                    const params = new URLSearchParams(search);
                    const token = params.get('token');
                    axios({
                        method:'post',
                        url:'https://api.buildrun.app/main/website/actions/decline',
                        data:{
                            token,
                            reason:'na'
                        }
                    }).then(r=>{
                        this.setState({
                            ...this.state,
                            decline:true
                        })
                    })
                }}>
                    Decline
                </div>
                </>:null
}

            </div>
        </div>
    )
}
}

export default Approve;