import React from 'react';
import { Switch,Route } from 'react-router';
import Approve from './Approve';


const Collections = () => {

    return(
        <Switch>
            <Route path="/collections/approve" component ={Approve} />
        </Switch>
    )
}

export default Collections;