import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { Switch, Route } from 'react-router-dom';
import Demos from './Common/Demos';
import Approve from './pages/Collections';
import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import ProfileThreeColGrid from "components/cards/ProfileThreeColGrid";
import AllProject from "pages/Project/index";
import PrivacyPolicy from "pages/PrivacyPolicy";


export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <Switch>
      <Route path="/" component={SaaSProductLandingPage} exact />
      <Route path="/project" component={AllProject} />
      {/* <Route path="/team" component={ProfileThreeColGrid} /> */}
      <Route path="/demos" component={Demos} exact />
      <Route path="/collections" component={Approve} />
      <Route path="/privacy" component={PrivacyPolicy} />
    </Switch>
  );
}

