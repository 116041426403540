import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://www.buildrun.app/ (the “Site”). <br></br>

By using our website, you hereby consent to our Privacy Policy and agree to its terms. The Grievance officer for any grievances is Mr. karan kumar (+91 8871636718, karan@buildrun.app)<br></br>

<b>PERSONAL INFORMATION WE COLLECT</b><br></br>

When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
<br></br>
https://www.buildrun.app/ follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
<br></br>
Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, debit card numbers ), email address, and phone number. We refer to this information as “Order Information.”
<br></br>
Note that https://www.buildrun.app/ has no access to or control over these cookies that are used by third-party advertisers.
<br></br>
When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
<br></br>
<b>HOW DO WE USE YOUR PERSONAL INFORMATION?</b><br></br>

We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:
<br></br>
1) Communicate with you;<br></br>
2) Improve, personalize, and expand our website;<br></br>
3) Understand and analyze how you use our website;<br></br>
4) Develop new products, services, features, and functionality;<br></br>
5) Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes;<br></br>
6) Screen our orders for potential risk or fraud; and<br></br>
7) When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.<br></br>
8) We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).<br></br>

<b>SHARING YOUR PERSONAL INFORMATION</b><br></br>

We share your Personal Information with third parties to help us use your Personal Information, as described above.<br></br>
https://www.buildrun.app/ Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.<br></br>

<b>BEHAVIOURAL ADVERTISING</b><br></br>
As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. <br></br>

<b>DO NOT TRACK</b><br></br>

Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.<br></br>

<b>DATA RETENTION</b><br></br>

When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.<br></br>

CHANGES

We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.<br></br>

<b>CHILDREN'S INFORMATION</b><br></br>

https://www.buildrun.app/ does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.<br></br>
<b>
CONTACT US</b><br></br>

The Grievance officer for any complaints or grievances is Mr. karan kumar (+91 8871636718, karan@buildrun.app). For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at karan@buildrun.app or by mail using the details provided below:<br>
</br>
LA LAGUNE HOUSE NO-1702 BLOCK-D, SECTOR-54, GOLF COURSE ROAD, GURGAON, Gurgaon, Haryana, 122002<br></br>



        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
