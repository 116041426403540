import React from 'react';
import './index.css';
import buildrun from '../Images/buildrun.png';
import { InputForm } from '../Input';
import { showToast } from '../Toast/ToastCallback';
import axios from 'axios';
import second from '../Images/1.png';


class Demos extends React.Component {


    state = {
        name: '',
        email: '',
        companyName: '',
        position: '',
        employees: '',
        mobile: '',
        industry: '',
        
    }



    componentDidMount() {

    }


    submit = () => {




        let name = this.state.name;
        let email = this.state.email;
        let companyName = this.state.companyName;
        let position = this.state.position;
        //let employees = this.state.employees;
        let mobile = this.state.mobile;
        let industry = this.state.industry;
       
        if (!name) {
            showToast({
                type: 'error',
                message: 'Please enter your name'
            })
            return;
        }
        if (!email) {
            showToast({
                type: 'error',
                message: 'Please enter your email'
            })
            return;
        }
        if (!companyName) {
            showToast({
                type: 'error',
                message: 'Please enter your company Name'
            })
            return;
        }
        if (!position) {
            showToast({
                type: 'error',
                message: "Please enter your position"
            })
            return;
        }

        if (!mobile) {
            showToast({
                type: 'error',
                message: 'Please enter a valid mobile number'
            })
            return;
        }
        if (!industry) {
            showToast({
                type: 'error',
                message: 'Please enter a valid industry details'
            })
            return;
        }

        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const e = regexp.test(this.state.email);
        if (!e) {
            showToast({
                type: 'error',
                message: 'Please enter a valid email'
            })
            return;
        }

        mobile = this.state.mobile;
        if (mobile.length !== 10 || mobile.charAt(0) === '0' || mobile.charAt(0) === '1' || mobile.charAt(0) === '2' || mobile.charAt(0) === '3' || mobile.charAt(0) === '4' || mobile.charAt(0) === '5') {
            showToast({
                type: 'error',
                message: 'Please enter 10 ten valid mobile number'
            })
            return;
        }

        // let data = {
        //     name,email,companyName,position,employees,mobile,industry
        // }

        axios({
            method: 'post',
            url: `https://api.buildrun.app/main/website/mail`,
            data: {
                name: `${this.state.name}`,
                email: `${this.state.email}`,
                company: this.state.companyName,
                location: `${this.state.position} `,
                revenue: this.state.industry,
                mobile: this.state.mobile
            }
        })
            .then(res => {
                this.setState({
                    name: '',
                    email: '',
                    companyName: '',
                    position: '',
                    industry: '',
                    mobile: ''
                })
                showToast({
                    type: 'success',
                    message: 'Form submit successfully'
                })
                return;
            })
    }

    render() {
        return (
            <div id="demos">
                <div className="demos-outer">
                    <div className="demos-left">
                        <div>
                            <img onClick={() => this.props.history.push('/')} src={buildrun} alt="Buildrun"
                                style={{
                                    width: 50,
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }} />
                        </div>
                        <p className="demos-left-header">Build Your Project on <br></br>Time Every Time.</p>
                        <p className="demos-left-desc">
                        Buildrun is a true construction process automation platform that helps builders plan, deploy, track & manage end to end project development
                        </p>
                        <div className="demos-left-form">
                            <div style={{ display: 'flex', flex: 1 }}>
                                <div style={{ flex: 1, marginRight: 12 }}>
                                    <InputForm value={this.state.name} type="text" title="Name"
                                        onChange={e => this.setState({
                                            ...this.state,
                                            name: e.target.value
                                        })}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <InputForm value={this.state.email} type="text" title="Email"
                                        onChange={e => this.setState({
                                            ...this.state,
                                            email: e.target.value
                                        })}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <div style={{ flex: 1, marginRight: 12 }}>
                                    <InputForm value={this.state.companyName} type="text" title="Company Name"
                                        onChange={e => this.setState({
                                            ...this.state,
                                            companyName: e.target.value
                                        })}

                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <InputForm value={this.state.position} type="text" title="Location"
                                        onChange={e => this.setState({
                                            ...this.state,
                                            position: e.target.value
                                        })}
                                    />
                                </div>
                            </div>


                            <div style={{ flex: 1 }}>
                                <InputForm value={this.state.mobile} type="number" title="Mobile Number"
                                    onChange={e => this.setState({
                                        ...this.state,
                                        mobile: e.target.value
                                    })}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <InputForm value={this.state.industry} type="text" title="Revenue"
                                    onChange={e => this.setState({
                                        ...this.state,
                                        industry: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                        <div onClick={this.submit} style={{
                            width: '100%',
                            textAlign: 'center',
                            backgroundColor:'rgb(100,21,255)',
                            color:'#fff',
                            padding:"12px 10px",
                            textTransform:'uppercase',
                            borderRadius:4
                        }} className="btn btn-portlet">Submit</div>
                    </div>
                    <div className="demos-right">
                        <div className="right-image">
                            <img src={second} alt = "" style ={{
                                height:'700px',
                                width:'800px'
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Demos;
