import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllProject from './AllProject';
// import ProjectDetails from './ProjectDetails';

const Project = () => {

    return (
        <Switch>
            <Route path="/project" component={AllProject} exact />
            {/* <Route path="/project/:name" component={ProjectDetails} exact /> */}
        </Switch>
    )
}

export default Project;